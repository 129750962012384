module.exports = function (data) {
	function toTop () {
		this.data = {};
	}

	toTop.prototype.handler = function (item) {
		let _this = this;
		let data = _this.data;

		if ($(window).scrollTop() > data.from_top) {
			item.addClass(data.active_class);
		} else {
			item.removeClass(data.active_class);
		}
	};

	toTop.prototype.init = function (upcomingData) {
		let _this = this;

		if (undefined === upcomingData) {
			_this.data = {
				active_class: 'js-active',
				from_top: 150,
				item_class: '.to-top',
				statik: false,
				speed: 600
			};
		} else {
			_this.data = upcomingData;
		}

		var item = $(_this.data.item_class);

		$(window).on('wheel', function (event) {
			$('body,html').stop();
		});

		if (_this.data.statik !== true) {
			$(window).scroll(function () {
				_this.handler(item);
			});
		}

		item.on('click', function () {
			$('html, body').stop().animate({
				scrollTop: $('body').offset().top
			}, _this.data.speed);
		});
	};

	toTop.prototype.init(data);
};
