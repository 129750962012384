'use strict';

class blockToggler {
	constructor (data = {}) {
		this.el = data.el;
		this.button = data.button;
		this.buttonId = this.uniqSmth();
		this.closeButton = data.closeButton;
		this.block = data.block;
		this.beforeOpen = data.beforeOpen;
		this.afterOpen = data.afterOpen;
		this.beforeClose = data.beforeClose;
		this.afterClose = data.afterClose;
		this.closeOnScroll = data.closeOnScroll;
		this.blockUniqClass = this.uniqSmth();
		this.overlay = data.overlay || false;
		this.opened = false;
		this.contentWrapper = document.body;

		this.init();
	}

	init () {
		this.block.addClass(this.blockUniqClass);
		this.button.attr('data-uniq-id', this.buttonId);

		this.clickInit();

		if (this.closeOnScroll) {
			this.onScrollInit();
		}
	}

	uniqSmth () {
		return 'uniq-class' + (parseInt(performance.now() + (Math.random() * 100)));
	}

	edge () {
		return window.navigator.userAgent.indexOf('Edge') > -1;
	}

	clickInit () {
		const _this = this;

		_this.button.on('click', function (evt) {
			if (!_this.opened) {
				_this.setActive();
			} else {
				_this.removeActive();
			}
		});

		_this.closeButton.on('click', function (evt) {
			_this.removeActive();
		});

		if (!_this.edge()) {
			$(document).on('click', function (evt) {
				let target = evt.target;

				if (_this.el.hasClass('is-active')) {
					if (target.closest('.' + _this.blockUniqClass) == null && !target.closest('[data-uniq-id=' + _this.buttonId + ']') && target !== $('[data-uniq-id=' + _this.buttonId + ']')) {
						_this.removeActive();
					}
				}
			});
		}
	}

	onScrollInit () {
		const _this = this;

		$(window).on('scroll', function (evt) {
			_this.removeActive();
		});
	}

	setActive () {
		this.opened = true;
		this.beforeOpen();
		this.el.addClass('is-active');
		if (this.overlay) {
			this.contentWrapper.style.overflow = 'hidden';
		}
		this.button.addClass('is-hidden');
		this.afterOpen();
	}

	removeActive () {
		this.opened = false;
		this.beforeClose();
		this.el.removeClass('is-active');
		if (this.overlay) {
			this.contentWrapper.style.overflow = 'auto';
		}
		this.button.removeClass('is-hidden');
		this.afterClose();
	}
};

export default blockToggler;
