'use strict';

/**
 * Подключение библиотеки jQuery
 * @module
 */

// ----------------------------------------
// Imports
// ----------------------------------------

import $ from 'jquery';

// ----------------------------------------
// Public
// ----------------------------------------

window.$ = window.jQuery = $;
