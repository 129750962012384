'use strict';

/**
 * @module
 */

// ----------------------------------------
// Imports
// ----------------------------------------

import ModuleLoader from 'wezom-module-loader';

// ----------------------------------------
// Public
// ----------------------------------------

const moduleLoader = new ModuleLoader({
	debug: IS_DEVELOP,
	importPromise: moduleName => import('./_modules-loaders/' + moduleName),
	initSelector: '.js-init',
	initFunctionName: 'loaderInit',
	loadingClass: '_module-loading',
	loadedClass: '_module-loaded',
	list: {
		/* eslint-disable key-spacing */
		'jquery-validation--module-loader': 'form',
		'inputmask--module-loader':         '[data-phonemask]',
		's2s--module-loader':               '[data-s2s]',
		'magnific-popup--module-loader':    '[data-mfp]',
		'wrap-media--module-loader':        '[data-wrap-media]',
		'prismjs--module-loader':           '[data-prismjs]',
		'lozad--module-loader':             ['[data-lozad]', 'picture'],
		'google-map--module-loader':        '[data-google-map]',
		'draggable-table--module-loader':   '[data-draggable-table]',
		'accordion--module-loader':         '[data-accordion]'
	}
});

// ----------------------------------------
// Exports
// ----------------------------------------

export default moduleLoader;
